import { useAddCourseToCartMutation } from "@/redux/features/api/cart/cartApiSlice";
import { useRouter } from "next/navigation";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { FiAlertTriangle } from "react-icons/fi";
import isNotStudent from "@/utils/auth/roles/isNotStudent";
import { Dispatch, SetStateAction } from "react";

const useAddToCart = (SetIsAdd?: Dispatch<SetStateAction<boolean>>) => {
    const [addCourseToCart] = useAddCourseToCartMutation();
    const router = useRouter();
    const handleAddToCart = async (courseId: string | undefined, goLink?: boolean, buybtn = false) => {
        if (!Boolean(Cookies.get('loggedIn'))) {
            return buybtn ? toast.error("لابد من تسجيل الدخول لتستطيع شراء الدورة", {
                icon: <FiAlertTriangle size={25} color={"#FFAE00"} />,
            }) : toast.error("لابد من تسجيل الدخول لإضافة الدورة الي العربة", {
                icon: <FiAlertTriangle size={25} color={"#FFAE00"} />,
            });
        } else if (isNotStudent(Cookies.get('role'))) {
            return toast.error("ليس لديك الصلاحية لاتخاذ هذا الإجراء");
        } else {
            if (courseId) {
                try {
                    const resData = await addCourseToCart({ courseId }).unwrap();
                    toast.success("تم إضافة الدورة التعليمية للعربة بنجاح");
                    SetIsAdd && SetIsAdd(true);
                    goLink && router.push("/user/cart");
                } catch (err: any) {
                    if (err && err?.status === 409) {
                        !goLink && toast.error("هذه الدورة التعليمة مضافة للعربة أو أنك تمتلكها في دوراتك")
                        goLink && router.push("/user/cart");
                    } else {
                        toast.error("فشلت عملية إضافة الدورة التعليمية للعربة");
                    }
                }
            }
        }
    }
    return { handleAddToCart }
}

export default useAddToCart;