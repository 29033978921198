import { useRemoveItemFromCartMutation } from "@/redux/features/api/cart/cartApiSlice";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { FiAlertTriangle } from "react-icons/fi";
import isNotStudent from "@/utils/auth/roles/isNotStudent";
import { Dispatch, SetStateAction } from "react";

const useRemoveCart = (SetIsAdd?: Dispatch<SetStateAction<boolean>>) => {
    const [removeItemFromCart] = useRemoveItemFromCartMutation();
    const handleRemoveCart = async (courseId: string | undefined) => {
        if (!Boolean(Cookies.get('loggedIn'))) {
            return toast.error("لابد من تسجيل الدخول لإضافة الدورة إلى العربة", {
                icon: <FiAlertTriangle size={25} color={"#FFAE00"} />,
            });
        } else if (isNotStudent(Cookies.get('role'))) {
            return toast.error("ليس لديك الصلاحية لاتخاذ هذا الإجراء");
        } else {
            if (courseId) {
                try {
                    const resData = await removeItemFromCart(courseId).unwrap();
                    toast.success("تم حذف الدورة التعليمية من العربة بنجاح");
                    SetIsAdd && SetIsAdd(false);
                } catch (err: any) {
                    toast.error("فشلت عملية حذف الدورة التعليمية من العربة");
                }
            }
        }
    }
    return { handleRemoveCart }
}

export default useRemoveCart;