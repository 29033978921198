type Props = {
    errServerMsg: string | string[],
    errRef?: any
}
const ServerError = ({ errServerMsg, errRef }: Props) => {
    return (
        <div>
            {errServerMsg && <div ref={errRef} className="err-server-msg">
                {typeof errServerMsg === 'string' && `🚫 ${errServerMsg}`}
                {Array.isArray(errServerMsg) && errServerMsg.map((el, inx) => (
                    <p key={inx}>🚫 {el}</p>
                ))}
            </div>}
        </div>
    );
}

export default ServerError;