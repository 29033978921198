import getCountry from "./getCountry";

const getPrice = (
    priceInEGP: number,
    priceInUSD: number,
    country: any = getCountry()
): string => {
    let price = null;
    if (country) {
        if (priceInEGP && priceInUSD && priceInEGP > 0 && priceInUSD > 0) {
            if (country === "Egypt") {
                price = priceInEGP + "£";
                return price;
            } else {
                price = priceInUSD + "$";
                return price;
            }
        }
    } else {
        if (priceInEGP && priceInUSD && priceInEGP > 0 && priceInUSD > 0) {
            if (getCountry() === "Egypt") {
                price = priceInEGP + "£";
                return price;
            } else {
                price = priceInUSD + "$";
                return price;
            }
        }
    }
    return "";
};

export const getPercentagePrice = (
    priceInEGP: number,
    priceInUSD: number,
    country: any = getCountry()
): string => {
    const price =
        priceInEGP && priceInUSD
            ? country === "Egypt"
                ? priceInEGP + "%"
                : priceInUSD + "%"
            : "";
    return price;
};

export const calcPercentageOfPrice = (
    priceAfterDiscount: number | undefined,
    originalPrice: number | undefined
): number => {
    const percentage =
        priceAfterDiscount && originalPrice && originalPrice > 0
            ? ((originalPrice - priceAfterDiscount) / originalPrice) * 100
            : 0;
    return percentage;
};

export const checkPriceNotZero = (price: number | string): boolean => {
    return typeof price === "string"
        ? Number(price.slice(0, -1)) > 0
        : price > 0;
};

export const getCurrency = (currency: string | null | undefined): string => {
    return currency === "EGP" ? "£" : currency === "USD" ? "$" : "";
};

export default getPrice;
