import Image from "next/image";
import RecentDemoImage from "@/../public/images/card-image.jpg";
import Link from "next/link";

type Props = {
    id?: string | undefined;
    forwardRef?: any;
    image: string;
    category: string;
    newClass?: string;
    children?: React.ReactNode;
    noLink?: boolean;
};

function CourseCardNoLink({
    id,
    forwardRef,
    image,
    category,
    newClass,
    children,
    noLink
}: Props) {

    return (
        <div className="courses-card-box">
            <div className={`courses-card ${newClass}`} ref={forwardRef}>
                {noLink ? <div className="larg-courses-card">
                    <div className="image-hand">
                        <Image
                            src={image || RecentDemoImage}
                            alt="كارد الدورة"
                            width={250}
                            height={150}
                        />
                    </div>
                </div> :
                    <Link prefetch={false} href={`/course/${id}`} className="larg-courses-card">
                        <div className="image-hand">
                            <Image
                                src={image || RecentDemoImage}
                                alt="كارد الدورة"
                                width={250}
                                height={150}
                            />
                        </div>
                    </Link>}
                {children && children}
                <div className="card-category" title={category}>
                    <Link prefetch={false} href={`/course/${id}`}>
                        {category}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CourseCardNoLink;
