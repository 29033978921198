"use client";
import { useState, useEffect } from "react";

type parameter = {
    size?: string | number;
    color1: string;
    color2: string;
    value: number;
    edit: boolean;
    half: boolean;
    onChange?: any;
    onHover?: any;
};

const useReactStars = ({
    size,
    color1 = "gray",
    color2 = "#ffd700",
    value = 0,
    edit = true,
    half = true,
    onChange,
    onHover,
}: parameter) => {
    const [rateValue, setRateValue] = useState(value);
    const [rateValueHover, setRateValueHover] = useState(rateValue);

    const [cursorStatus, setCursorStatus] = useState(color1);
    const [fontSize, setFontSize] = useState(size);
    useEffect(() => {
        setRateValue(value);
    }, [value])
    useEffect(() => {
        edit ? setCursorStatus("pointer") : setCursorStatus("auto");
        typeof fontSize === "number" && setFontSize(`${fontSize}px`);
    }, [edit, fontSize]);

    useEffect(() => {
        onChange && onChange(rateValue);
    }, [rateValue, onChange]);

    useEffect(() => {
        onHover && onHover(rateValueHover);
    }, [rateValueHover, onHover]);

    const handleValue = (inx: number, AddHalf: number) => {
        if (edit) {
            half
                ? setRateValue(inx + AddHalf)
                : setRateValue(Math.ceil(inx + AddHalf));
        }
    };

    const handleHover = (inx: number, AddHalf: number) => {
        if (edit) {
            half
                ? setRateValueHover(inx + AddHalf)
                : setRateValueHover(Math.ceil(inx + AddHalf));
        }
    };

    const handleColor = (inx: number, removeHalf: number = 0): string => {
        const color = inx + removeHalf < rateValueHover ? color2 : color1;
        return color;
    };

    return { fontSize, handleColor, cursorStatus, handleValue, handleHover };
};

export default useReactStars;
