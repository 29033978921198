"use client";

import { apiPurchaseSlice } from "@/redux/app/api/apiSlice";

export const purchaseApiSlice = apiPurchaseSlice.injectEndpoints({
    endpoints: (builder) => ({
        // logged
        getAllLoggedPurchase: builder.query({
            query: (params) => ({
                url: `/api/v1/purchases`,
                params: { ...params },
            }),
            providesTags: ["Purchase"],
        }),
        // admin
        getListPurchaseUser: builder.query({
            query: (userId) => ({
                url: `/api/v1/dashboard/purchases/${userId}`,
            }),
            providesTags: ["Purchase"],
        }),
        addFreeCourseToMyCourse: builder.mutation({
            query: (courseItem) => ({
                url: "/api/v1/purchases",
                method: "POST",
                body: { ...courseItem },
            }),
            invalidatesTags: ["Purchase"],
        }),
        deletePurchase: builder.mutation({
            query: (purchaseId) => ({
                url: `/api/v1/dashboard/purchases/${purchaseId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Purchase"],
        }),
    }),
});

export const {
    useGetAllLoggedPurchaseQuery,
    useGetListPurchaseUserQuery,
    useAddFreeCourseToMyCourseMutation,
    useDeletePurchaseMutation,
} = purchaseApiSlice;
