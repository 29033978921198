import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { FiAlertTriangle } from "react-icons/fi";
import isNotStudent from "@/utils/auth/roles/isNotStudent";
import { useAddFreeCourseToMyCourseMutation } from "@/redux/features/api/purchase/purchaseApiSlice";

const useAddToMyCourses = () => {
    const [addFreeCourseToMyCourse] = useAddFreeCourseToMyCourseMutation();
    const handleAddToMyCourses = async (courseId: string | undefined) => {
        if (!Boolean(Cookies.get('loggedIn'))) {
            return toast.error("لابد من تسجيل الدخول لإضافة الدورة الي دوراتك", {
                icon: <FiAlertTriangle size={25} color={"#FFAE00"} />,
            });
        } else if (isNotStudent(Cookies.get('role'))) {
            return toast.error("ليس لديك الصلاحية لاتخاذ هذا الإجراء");
        } else {
            if (courseId) {
                try {
                    const resData = await addFreeCourseToMyCourse({ courseId }).unwrap();
                    toast.success("تم إضافة الدورة التعليمية لدوراتك بنجاح");
                } catch (err: any) {
                    if (err && err?.status === 409) {
                        toast.error("أنت تمتلك هذه الدورة بالفعل")
                    } else {
                        toast.error("فشلت عملية إضافة الدورة التعليمية للعربة");
                    }
                }
            }
        }
    }
    return { handleAddToMyCourses }
}

export default useAddToMyCourses;