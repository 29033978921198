import { apiCartSlice } from "@/redux/app/api/apiSlice";

export const CartApiSlice = apiCartSlice.injectEndpoints({
    endpoints: (builder) => ({
        // logged
        getCart: builder.query({
            query: (params) => ({
                url: `/api/v1/cart`,
                params: { ...params },
            }),
            providesTags: ["Cart"],
        }),
        addCourseToCart: builder.mutation({
            query: (cartItem) => ({
                url: "/api/v1/cart",
                method: "POST",
                body: { ...cartItem },
            }),
            invalidatesTags: ['Cart']
        }),
        applyCouponToCart: builder.mutation({
            query: (cartItem) => ({
                url: `/api/v1/cart/apply-coupon`,
                method: "PUT",
                body: { ...cartItem },
            }),
            invalidatesTags: ["Cart"],
        }),
        removeItemFromCart: builder.mutation({
            query: (cartId) => ({
                url: `/api/v1/cart/${cartId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Cart"],
        }),
    }),
});

export const {
    useGetCartQuery,
    useAddCourseToCartMutation,
    useApplyCouponToCartMutation,
    useRemoveItemFromCartMutation,
} = CartApiSlice;
