type props = {
    size?: string;
    type?: string;
    newClass?: string;
}
const LoadingEffect = ({ size, type, newClass }: props) => {
    return (
        <span className={`loading-effect-cssloaders ${size} ${type} ${newClass}`}></span>
    );
}

export default LoadingEffect;