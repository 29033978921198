'use client'
import useReactStars from "@/hooks/common/reactStare/useReactStars";

type Props = {
    userSelect?: boolean;
    value?: number;
    edit?: boolean;
    count?: number;
    size?: string | number | undefined;
    color1?: string;
    color2?: string;
    className?: string | null;
    char?: string;
    half?: boolean;
    dir?: "rtl" | "ltr";
    animation?: boolean;
    onChange?: any;
    onHover?: any;
    title?: string;
}
const ReactStare = ({
    userSelect = false,
    count = 5,
    size,
    className = null,
    char = "★",
    color1 = "gray",
    value = 0,
    edit = true,
    color2 = "#ffd700",
    half = true,
    dir = "ltr",
    animation = true,
    onChange,
    onHover,
    title
}: Props) => {
    const { fontSize, handleColor, cursorStatus, handleValue, handleHover } = useReactStars({ size, color1, value, edit, color2, onChange, onHover, half });

    return (
        <div dir={dir} className={`react-stars-component ${className}`} style={{
        }}>
            {Array(count).fill(char).map((el, inx) => (
                <div key={inx} style={{ fontSize: fontSize && fontSize, color: color1 }} className="stars-item" title={title}>
                    {el}
                    <span className={`stars-item-over start ${animation && animation}`} style={{ color: handleColor(inx), cursor: cursorStatus }} onClick={() => handleValue(inx, 0.5)} onMouseOver={() => handleHover(inx, 0.5)} onMouseMove={() => handleHover(inx, 0.5)} onMouseLeave={() => handleHover(inx, 0.5)}>{el}</span>
                    <span className={`stars-item-over end ${animation && animation}`} style={{ color: handleColor(inx, 0.5), cursor: cursorStatus }} onClick={() => handleValue(inx, 1)} onMouseOver={() => handleHover(inx, 1)} onMouseMove={() => handleHover(inx, 1)} onMouseLeave={() => handleHover(inx, 1)}>{el}</span>
                </div>
            ))}
        </div>
    );
}

export default ReactStare;